<template>
  <ul>
      <!-- <li>
          <a href="http://www.thecorporatechoice.com/" target="_blank">Corporate Choice</a>
      </li> -->
      <li>
          <a href="https://www.4brandedimprint.com/collegehype/home" target="_blank">Imprintable Fashion (AB)</a>
      </li>
      <li>
          <a href="https://www.pacificheadwear.com/" target="_blank">Stylish Hats</a>
      </li>
      <li>
          <a href="https://www.sportswearcollection.com/collegehype" target="_blank">Sportswear Collection (S-S)</a>
      </li>
      <li>
          <!-- <a href="http://www.companycasuals.com/CompanyCasuals/start.jsp" target="_blank">Company Casuals</a> -->
          <a href="https://companycasuals.com/collegehype2" target="_blank">Company Casuals (SM)</a>
      </li>
      <li>
          <a href="https://collegehype.espwebsite.com/" target="_blank">All Promotional Products</a>
      </li>
  </ul>
</template>

<script>
  export default {
    name: 'CatalogProviderLinks',

    data: () => ({
      
    }),
  }
</script>
